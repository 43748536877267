<template>
    <div style="display: flex; justify-content: flex-start">
        <el-upload
            class="avatar-uploader"
            :action="ossInfo.ossSignature.host"
            :show-file-list="false"
            :auto-upload="false"
            list-type="picture-card"
            ref="uploadImg"
            :on-change="handleChange"
            :data="fileDataObj"
            :on-error="uploadError"
            accept=".jpg, .jpeg, .png, .gif"
        >
            <el-image v-if="imageUrl" class="avatar" :src="imageUrl && ossHost + imageUrl" fit="contain"></el-image> 
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

            
        </el-upload>
        <span style='margin-left: 20px;'>{{$i18n.t("common.RecommendedSize100")}}</span>
    </div>
</template>

<script>
import X2JS from 'x2js'; //解析xml
export default {
    props: {
        img: String
    },
    mounted() {
        this.getOssSignature();
    },
    data() {
        return {
            fileDataObj: {},

            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: { host: '' },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },
            ossHost: process.env.VUE_APP_OSS_Head,

            imageUrl: this.img
        };
    },
    methods: {
        event() {
            this.$emit('event', this.imageUrl);
        },
        
        handleChange(file, fileList) {
            // console.log('=========',fileList,file)
            const isLt = file.size / 1024  <= 20;
            if (!isLt) {
                this.$message.error(this.$i18n.t("common.Thesizeoftheuploadedimagecannotexceed") + ' 20KB!');
                this.$refs.uploadImg.clearFiles();
                return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            this.fileDataObj = dataObj;
            console.log('dataObj', this.ossInfo.ossHost);
            let that = this;
            setTimeout(() => {
                this.$refs.uploadImg.submit();
                var x2js = new X2JS();
                var jsonObj = x2js.xml2js(file.response);
                let ossUrl = jsonObj.PostResponse.Location;
                let zi = 'com';
                if (ossUrl.indexOf(zi) !== -1) {
                    that.imageUrl = ossUrl.substr(ossUrl.lastIndexOf(zi) + zi.length);
                }   
                this.$refs.uploadImg.clearFiles(); 
                that.event();
            }, 500);
        },
        uploadError(){
            this.$refs.uploadImg.clearFiles(); 
            this.$message.error('文件上传失败！');
        },
        //获取OSS签名
        getOssSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2c'
                })
                .then((res) => {
                    console.log(res);
                    this.ossInfo.ossSignature = res;
                    this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                });
        },
        /**
         * 生成随机文件名
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },

        getSuffix(filename) {
            let pos = filename.lastIndexOf('.');
            let suffix = '';
            if (pos != -1) {
                suffix = filename.substring(pos);
            }
            return suffix;
        }
    }
};
</script>



<style scoped>
	
 
    
    .avatar-uploader /deep/ .el-upload--picture-card {
		width: 100px;
		height: 100px;
        line-height: 100px;
	}

    .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.avatar {
		height: 100px;
		display: block;
	}

</style>
 
