<template>
	<div class="app-container" style="height: 100%;min-height:350px;">
		<filter-container :arg="arg"  :defaultListQuery="defaultListQuery" :placeholder='placeholder' :dictionary="dictionary" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch'></filter-container>
		<div style="margin: 10px 0px 20px 20px;">
			<el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)">{{ $i18n.t("common.Add") }}</el-button>
		</div>
		<el-table ref="tableData" :data="pageData.list" style="width: 100%" height="calc(100% - 190px)" border :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="goodsClassifyId" :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
			<el-table-column prop="level" :label="$i18n.t('common.Directoryhierarchy')" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="title" :label="$i18n.t('common.Categoryname1')" align="center" show-overflow-tooltip></el-table-column>		
			<el-table-column prop="sort"  :label="$i18n.t('common.sort')" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="imageUrl" :label="$i18n.t('common.picture')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
<!--					<el-image style="width: 100px; height: 100px" v-if="scope.row.backgroundImage.length>0 && scope.row.backgroundImage != ''"-->
<!--						:src="ossHost+scope.row.backgroundImage" fit="fill"></el-image>-->
					<listImage :imgUrl="scope.row.backgroundImage"></listImage>
				</template>
			</el-table-column>
			<!--<el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip></el-table-column>-->
			<el-table-column prop="status" :label="$i18n.t('common.Enableornot')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<el-switch
					@change="updateStatus(scope.row)"
					  v-model="scope.row.status"
					  :active-value="1"
					  :inactive-value="0"
					  active-color="#13ce66"
					  inactive-color="#ff4949">
					</el-switch>
				</template>	
			</el-table-column>
			<el-table-column prop="appStatus" v-if="scene.isAigoSmartApp" :label="$i18n.t('common.Isthehomepagedisplayed')" align="center"
				show-overflow-tooltip>
				<template slot-scope="scope">
					<el-switch @change="updateHomeShow(scope.row)" v-model="scope.row.appStatus" :active-value="1"
						:inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span>{{scope.row.updateTime | formatDate}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="updateByName" :label='$i18n.t("common.Operator")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.updateByName">{{ row.updateByName }}</span>
                    <span v-else>{{ $i18n.t("common.SystemAutoUpdate") }}</span>
                </template>
            </el-table-column>			
			<el-table-column :label="$i18n.t('common.operation')" align="center" fixed="right" width="400">
				<template slot-scope="scope">
					<el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update',module_code)">{{ $i18n.t('common.edit') }}</el-button>
					<el-button @click="openLan(scope.row)" type="text" size="small">{{ $i18n.t('common.Multilingualconfiguration') }}</el-button>
					<el-button @click="openRelaMaj(scope.row)" type="text" size="small" v-if="!scene.isNonSelf" >{{ $i18n.t('common.Relatedimportantparameters') }}</el-button>
					<el-button @click="openRelaSearch(scope.row)" type="text" size="small" v-if="!scene.isNonSelf" >{{ $i18n.t('common.Associatesearchparameters') }}</el-button>
					<el-button @click="handleDelete(scope.row)" type="text" size="small">{{ $i18n.t('common.delete') }}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block pagination-container">
			<el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
				:page-size="listQuery.limit" layout="total, prev, pager, next, jumper" :total="pageData.total">
			</el-pagination>
		</div>
		<!-- 关联参数 -->
		<el-dialog :title="parameterGroupTitle==true?$i18n.t('common.Relatedimportantparameters'):$i18n.t('common.Associatesearchparameters')" :visible.sync="dialogRelaMajVisible" :before-close="closeRelaMajDialog" :close-on-click-modal="false">
			<div v-if="dialogRelaMajVisible">
				<el-tree ref="tree" :props="defaultProps" :data="options" node-key="parameterItemId" :default-checked-keys="checkedKeys"  show-checkbox>
				</el-tree>
				<div class="dialog-footer">
					<el-button style="width: 20%;" @click="closeRelaMajDialog">{{ $i18n.t('common.cancel') }}</el-button>
					<el-button style="width: 20%;" type="primary" @click="onSubmitLang">{{ $i18n.t('common.confirm1') }}</el-button>
				</div>
			</div>		
		</el-dialog>
		<el-dialog :title="isEdit?$i18n.t('common.edit'):$i18n.t('common.Add')" :visible.sync="dialogAddVisible" :before-close="closeDialog"  :close-on-click-modal="false">
			<div v-if="dialogAddVisible">
				<add-dialog :isEdit='isEdit' :formListQuery='formListQuery' :dictionary="dictionary" @closeDialog="closeDialog" @handleSearchList="handleSearchList"/>
			</div>		    
		</el-dialog>
		<el-dialog :title="$i18n.t('common.Multilingualconfiguration')" :visible.sync="dialogLanVisible" :before-close="closeLangDialog"  :close-on-click-modal="false">
			<div v-if="dialogLanVisible">
				<el-form ref="titleFrom" label-width="150px">
					<el-form-item :label="$i18n.t('common.Categoryname1')">
						<div>{{langTitle}}</div>
					</el-form-item>
					<el-form-item v-for="item in languageList" :key="item.id" :label="item.name" prop="name">
						<el-input v-model="item.value" @input="forceupdate" :placeholder="$i18n.t('common.Pleaseenteraname')" autocomplete="off"></el-input>
					</el-form-item>
				</el-form>
				<div class="dialog-footer">
					<el-button style="width: 20%;" @click="closeLangDialog">{{ $i18n.t('common.cancel') }}</el-button>
					<el-button style="width: 20%;" type="primary" @click="updateTranslate">{{ $i18n.t('common.confirm1') }}</el-button>
				</div>
			</div>	    
		</el-dialog>

	</div>
</template>
<script>
	import TabsDialog from '@/components/common/TabsDialog.vue';
	import cache from '@/utils/cache';
	import config from '@/utils/config';
	import FilterContainer from '@/components/common/FilterContainer.vue';
	import AddDialog from './components/AddDialog.vue';
	import i18n from '@/i18n/i18n.js'
  import scene from '../../utils/scene';
	import listImage from '@/components/common/ListImage.vue';
    import dictionaryUtil from '@/utils/dictionaryUtil';

	const defaultFormListQuery={
		"title": '',
		"status": 1,
		'appStatus':0,
		"parentId":"",
		"brandCode": 'AigoStar',	
		"sort": 0,
		"icon":"",
		"backgroundImage":"",
		"compId":"",	
		"countryCode": "",
		"bannerCountriesList":[]
	};
	const defaultDictionary={
		brandCode:dictionaryUtil.getDict('brand'),
		parentId:[{
			id:'1',
			name:'AigoStar',
			value:'AigoStar'
		},{
			id:'2',
			name:'Nobleza',
			value:'Nobleza'
		},{
			id:'3',
			name:'Unico',
			value:'Unico'
		},{
			id:'4',
			name:'Topject',
			value:'TOPJECT'
		}],
		country:dictionaryUtil.getDict('countrys'),
		status:[{
			id:'1',
			name:i18n.t('common.yes'),
			value:true
		},{
			id:'2',
			name:i18n.t('common.no'),
			value:false
		}],
		appStatus:[{
			id:'1',
			name:i18n.t('common.yes'),
			value:1
		},{
			id:'2',
			name:i18n.t('common.no'),
			value:0
		}]
	};
	const defaultlanguageList=dictionaryUtil.getDict('lang')
	export default {
    computed: {
      scene() {
        return scene
      }
    },
		components: {
			TabsDialog,
			FilterContainer,
			AddDialog,
			listImage
		},
		name: 'goods',
		data() {
			return {
				module_code:config.module_code.classAdmin,
				//编辑or新增
				isEdit: false,
				ossHost: process.env.VUE_APP_OSS_Head,
				//行信息
				rowInfo:{},
				langValList:[],
				//变体
				options: [],
				//关联列表
				relationList:[],
				//多语言ID
				rowGoodsClassifyId:"",
				listQuery:{
					"status":"",
					"appStatus":'',
					"correlationId":"",
					"parentId":"",
					"level":"",
					"compId": "",
					"title": "",
					"brandCode": "",
					"company":"",
					"page": 1,
					"limit": 10
				},
				defaultListQuery:{
				  "status":"",
				  "appStatus":'',
				  "correlationId":"",
				  "parentId":"",
				  "level":"",
				  "compId": "",
				  "title": "",
				  "brandCode": "",
				  "company":"",
				  "page": 1,
				  "limit": 10
				},
				languageList:JSON.parse(JSON.stringify(defaultlanguageList)),
				formListQuery:Object.assign({}, defaultFormListQuery),
				dictionary:Object.assign({}, defaultDictionary),
				pageData:{
					list:[]
				},
				langTitle:"",
				//选中的tree
				checkedKeys:[],
				checkedKeys1:[],
				//组件标签是否显示
				arg:{
					name:true,
					enable:true,
					// brand:true,
					appStatus: false
				},
				argFrom2: {
					parameterName:true,
					parameter:true					
				},
				placeholder:{
					name:this.$i18n.t('common.Categoryname1')
				},
				defaultProps:{
					label:'title',
					id:'parameterGroupId',
					children:'parameterItems',
					disabled:function(data,node){
						if(data.parameterItems && data.parameterItems.length>0){
							return true
						}else{
							return false            
						}        
					}    			            
				},
				//选中的参数
				treeCheckedList:[],
				//关联参数
				parameterGroupTitle:"",
				dialogAddVisible:false,
				dialogLanVisible:false,
				dialogRelaMajVisible:false,
			};
		},
		
		mounted() {
			this.handleSearchList()
			this.getVarAdGroupItems()
			this.arg.appStatus = scene.isAigoSmartApp;
		},
		methods: {
			//搜索列表
			handleSearchList(listQuery){
				let status=""			
				if(listQuery){
					this.listQuery.page=1
					if (listQuery.enable == true || listQuery.enable == false) {
						status = listQuery.enable == true ? 1 : 0
					}
					this.listQuery={
						"status":status,
						"appStatus":listQuery.appStatus,
						"correlationId":"",
						"parentId":"",
						"level":"",
						"compId": "",
						"title": listQuery.title?listQuery.title:"",
						"brandCode": listQuery.brand?listQuery.brand:null,
						"company":"",
						"page": this.listQuery.page,
						"limit": this.listQuery.limit				
					}
				}
												
				this.$store
					.dispatch('getClassify', this.listQuery)
					.then(res => {					
						this.pageData=res
					});
			},
			add(){
				this.isEdit=false
				this.dialogAddVisible=true
			},
			edit(row){
				this.isEdit=true
				
				this.formListQuery= Object.assign({},row)
				if(row.countryCodes && row.countryCodes.length>0){
					this.$set(this.formListQuery,"bannerCountriesList",row.countryCodes)	
				}else{
					this.$set(this.formListQuery,"bannerCountriesList",[])
				}
				setTimeout(()=>{
					this.dialogAddVisible=true
				})					
			},
			forceupdate(){
				this.$forceUpdate()
			},
			//修改状态
			updateStatus(row){
				this.$store
					.dispatch('uodateStatus',{
						status:row.status,
						id:row.goodsClassifyId,
					})
					.then(res => {
						this.handleSearchList()
					});
			},
			updateHomeShow(row){
				this.$store
					.dispatch('updateHomeShow',{
						status:row.appStatus,
						id:row.goodsClassifyId,
					})
					.then(res => {
						this.handleSearchList()
					});
			},
			getTranslateLang(goodsClassifyId){
				this.$store
					.dispatch('getTranslateLang',{
						id:goodsClassifyId
					})
					.then(res => {
						if(res && res.length>0){
							this.languageList.forEach((item,index)=>{
								res.forEach((item2,index2)=>{
									if(item.code==item2.lang){
										this.languageList[index].value=item2.title
									}
								})							
							})
						}else{
							this.languageList=JSON.parse(JSON.stringify(defaultlanguageList))
						}
						this.dialogLanVisible=true					
					});
			},
			updateTranslate(){
				if(this.rowGoodsClassifyId){
					let data=[]
					data=this.languageList.map(item=>{
						return {
							goodsClassifyId:this.rowGoodsClassifyId,
							title:item.value,
							lang:item.code
						}
					})
					this.$store
						.dispatch('updateTranslate',data)
						.then(res => {
							this.$message({
								message: this.$i18n.t('common.Successfullysubmitted'),
								type: 'success',
								duration: 1000
							});	
							this.handleSearchList()
						});
					this.dialogLanVisible=false		
				}else{
					console.log('this.rowGoodsClassifyId',this.rowGoodsClassifyId)
				}						
			},
			handleCurrentChange(val) {
				this.listQuery.page = val;
				this.handleSearchList();
			},
			//获取关联列表（重要）
			getTranslateRelation(type){
				this.checkedKeys=[]
				this.$store
					.dispatch('getTranslateRelation',{
						goodsClassifyId:this.rowInfo.goodsClassifyId, //this.rowInfo.goodsClassifyId
						parameterType:type  //type
					})
					.then(res => {
						this.relationList=res
						res.forEach((item,index)=>{
							this.checkedKeys.push(item.parameterItemId)
						})
						this.dialogRelaMajVisible=true
					});
			},
			//获取变体
			getVarAdGroupItems(){
				this.$store
					.dispatch('getVarAdGroupItems',{})
					.then(res => {					
						this.options=res.filter(item=>item.parameterItems && item.parameterItems.length>0)
					});	
			},
			openLan(row){
				this.langTitle=row.title
				this.rowInfo=row			
				this.rowGoodsClassifyId=row.goodsClassifyId
				this.getTranslateLang(row.goodsClassifyId)
			},
			//重要关联
			openRelaMaj(row){
				this.rowInfo=row
				this.parameterGroupTitle=true
				this.getTranslateRelation(1)			
			},
			//搜索关联
			openRelaSearch(row){
				this.rowInfo=row
				this.parameterGroupTitle=false
				this.getTranslateRelation(0)
			},
				// 删除操作
			handleDelete(row) {
				// 二次确认删除
				this.$confirm(this.$i18n.t('common.Areyousureyouwanttodeleteit'), this.$i18n.t('common.prompt'), {
					type: 'warning'
				}).then(() => {
					this.$store
						.dispatch('delClassify', {
								goodsClassifyId: row.goodsClassifyId,
						})
						.then(res => {
							this.handleSearchList()
							this.$message.success(this.$i18n.t('common.Successfullydeleted'));
						});              
				}).catch(() => {});
			},
			//保存关联参数
			onSubmitLang(){
				this.$confirm(this.$i18n.t('common.Whethertosubmitdata'), this.$i18n.t('common.prompt'), {
					confirmButtonText: this.$i18n.t('common.confirm1'),
					cancelButtonText: this.$i18n.t('common.cancel'),
					type: 'warning'
				}).then(() => {
					let type=null
					this.parameterGroupTitle==true?type=1:type=0
					let checkedList=this.$refs.tree.getCheckedKeys()
					if(checkedList.length<=0){
						this.$message({
								message: this.$i18n.t('common.Pleaseselectparameters'),
								type: 'warning',
								duration: 1000
						});
						return
					}
					let arr=[]
					let data=[]
					let array=[]
					this.options.forEach(item=>{
						item.parameterItems.forEach((item2)=>{
							arr.push(item2)
						})					
					})
					arr.forEach((item,index)=>{
						checkedList.forEach((item2,index2)=>{
							if(item.parameterItemId==item2){
								array.push(item)
							}
						})
					})
					data=array.map((item)=>{
						return {
							"goodsClassifyId":this.rowInfo.goodsClassifyId,
							"parameterItemId":item.parameterItemId,
							"parameterType":type,
							"sort":0,
							"company":item.company,
						}
					})
					this.$store
						.dispatch('updateTranslatePara',data)
						.then(res => {
							this.$message({
								message: this.$i18n.t('common.Successfullysubmitted'),
								type: 'success',
								duration: 1000
							});
							this.dialogRelaMajVisible=false
							this.handleSearchList()
						});	
				});
								
			},
			handleResetSearch(){
				this.listQuery = Object.assign({}, this.defaultListQuery);
				this.handleSearchList()
			},	
			//关闭窗口
			closeDialog(){
				this.dialogAddVisible=false
				this.formListQuery=Object.assign({}, defaultFormListQuery)
			},
			closeLangDialog(){
				this.dialogLanVisible=false
				this.languageList=JSON.parse(JSON.stringify(defaultlanguageList))
			},
			closeRelaMajDialog(){
				this.dialogRelaMajVisible=false
			},	
		}
	};
</script>
<style scoped>
	.input-width {
		width: 203px;
	}

	.down-template {
		text-decoration: underline;
		color: rgb(38, 166, 222);
		font-weight: 400;
		font-style: normal;
		font-size: 13px;
		line-height: 13px;
		margin: 20px 0px 0px 10px;
		cursor: pointer;
	}
	.ruku{
        color:#000000;
    }

	.pagination-container {
		background: #fff;
		padding: 20px 16px;
	}
	.pagination-container.hidden {
		display: none;
	}

</style>
